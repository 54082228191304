import styled from "styled-components"
import devices from "../../styles/devices"

import BannerEquipamentos from "../../assets/banner-equipamentos.jpg"

export const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${BannerEquipamentos});
  background-size: cover;
  color: white;
  font-family: "Montserrat";
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0 2px 0px rgba(0, 0, 0, 0.35);
  text-align: center;
  min-height: 150px;
  padding: 10px 0;
`

export const HeroTitle = styled.h1`
  font-size: 42px;

  @media (max-width: ${devices.mobileL}) {
    font-size: 28px;
  }
`

export const HeroSubtitle = styled.p`
  max-width: 540px;
  text-align: center;
  line-height: 24px;
`

export const Divider = styled.div`
  height: 4px;
  width: 62px;
  background: #fff;
  margin: 16px 0;
`
export const Gallery = styled.div`
  display: flex;
  flex-direction: column;
`

export const GalleryRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  img + img {
    margin-left: 20px;
  }

  img {
    object-fit: cover;
  }

  @media (max-width: ${devices.mobileL}) {
    flex-direction: column;

    margin: 0px;
    img {
      object-fit: cover;
      min-width: 100%;

      + img {
        margin: 0;
      }
    }
  }
`

export const Equipments = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`

export const Lists = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: ${devices.mobileL}) {
    flex-direction: column;
  }
`

export const List = styled.div`
  width: 480px;

  h3 {
    text-transform: uppercase;
  }

  ul {
    li {
      margin-top: 20px;
    }
  }

  @media (max-width: ${devices.mobileL}) {
    width: 100%;
  }
`
