import React from "react"
import styled from "styled-components"

import DefaultLayout from "../../layouts/default"

import {
  Hero,
  HeroTitle,
  Divider,
  HeroSubtitle,
  Gallery,
  GalleryRow,
  Equipments,
  Lists,
  List,
} from "./styles.styled"

import Equipamentos1 from "../../assets/imagem-01-equipamentos.jpg"
import Equipamentos5 from "../../assets/imagem-05-equipamentos.jpg"
import Equipamentos3 from "../../assets/imagem-03-equipamentos.jpg"
import Equipamentos4 from "../../assets/imagem-04-equipamentos.jpg"
import Orcamento from "../../assets/imagem-orcamento-equipamentos.jpg"

import Contact from "../../components/Contact"
import Content from "../../components/Content"
import Description from "../../components/Description"

export default () => (
  <DefaultLayout>
    <Hero>
      <HeroTitle>Locações inteligentes</HeroTitle>
      <Divider />
      <HeroSubtitle>
        Máquinas e Equipamentos para Mineração, Construção Civil, Pavimentação,
        Agricultura e Florestal
      </HeroSubtitle>
    </Hero>

    <Content>
      <Gallery>
        <GalleryRow>
          <img src={Equipamentos1} />
          <img src={Equipamentos5} width="280" height="280" />
        </GalleryRow>
        <GalleryRow>
          <img src={Equipamentos3} />
          <img src={Equipamentos4} />
        </GalleryRow>
      </Gallery>
      <Equipments>
        <Description centered title="Equipamentos" />
        <Lists>
          <List>
            <ul>
              <li>Retroescavadeira</li>
              <li>
                Escavadeira - 20t | 30t
                <br />
                com Concha ou Rompedor (picão)
              </li>
              <li>
                Miniescavadeira - 3t | 5t
                <br />
                com Concha ou Rompedor (picão)
              </li>
              <li>Minicarregadeira</li>
              <li>Motoniveladora</li>
              <li>Vibroacabadora de asfalto</li>
            </ul>
          </List>
          <List>
            <ul>
              <li>
                Rolos compactadores
                <br />
                Tandem | Pneumático | Pata
              </li>
              <li>Cavalo Mecânico | Prancha</li>
              <li>
                Caminhões - Pipa | Espargidor de asfalto
                <br />
                Guindauto (munck) | Caçamba - 6x2 | 6x4
              </li>
              <li>Caçamba Britadora | Peneira</li>
              <li>Cabeçote Desbastador Florestal</li>
              <li>
                Fresadora de asfalto a frio
                <br />
                Fresagem - 15mm | Microfresagem - 6mm
              </li>
            </ul>
          </List>
        </Lists>
      </Equipments>
    </Content>
    <Contact width="540px" title="Orçamento" negative background={Orcamento} />
  </DefaultLayout>
)
